const getConfig = require('next/config').default;

const publicRuntimeConfig = getConfig() ? getConfig().publicRuntimeConfig : null;

module.exports = {
  api: {
    commonUrl: publicRuntimeConfig ? publicRuntimeConfig.COMMON_URL : process.env.COMMON_URL,
    directoryUrl: publicRuntimeConfig ? publicRuntimeConfig.DIRECTORY_URL : process.env.DIRECTORY_URL,
    bookingUrl: publicRuntimeConfig ? publicRuntimeConfig.BOOKING_URL : process.env.BOOKING_URL,
    consumerUrl: publicRuntimeConfig ? publicRuntimeConfig.CONSUMER_URL : process.env.CONSUMER_URL,
    providerUrl: publicRuntimeConfig ? publicRuntimeConfig.PROVIDER_APP_URL : process.env.PROVIDER_APP_URL,
  },
  google: {
    maps: {
      element: 'google-maps-api',
      api: 'https://maps.googleapis.com/maps/api/js',
      key: 'AIzaSyCiySp3Vi96Ju3dk7PDn8P4iRLBJxDz9GE',
    },
  },
  sentry: {
    dsn: 'https://48532a735b5946e3a1da6ee41b643f19@o355979.ingest.sentry.io/5196491'
  },
  defaultApplicationSettings: {
    supportedLanguages: ['de']
  }
};
